/* bootstrap overwrite */
.columns-area .text-component,
.column-component .text-component {
  width: 100%;
}
.columns-area .video-component .headlines-container,
.columns-area .video-component .video-container,
.columns-area .video-component .text-container,
.column-component .video-component .headlines-container,
.column-component .video-component .video-container,
.column-component .video-component .text-container {
  width: 100%;
}