@import 'webresources-sources/scss/imports';

.columns-area,
.column-component {
  .text-component {
    width: 100%;
  }

  .video-component {
    .headlines-container,
    .video-container,
    .text-container {
      width: 100%;
    }
  }
}
